<template>
  <div>
    <div v-if="currentFile">
      <div>
        <v-progress-linear v-model="progress" color="light-blue" height="25" reactive>
          <strong>{{ progress }} %</strong>
        </v-progress-linear>
      </div>
    </div>

    <v-row class="ma-0" dense justify="center" >
      <v-col>
        <v-file-input show-size label="File input" @change="selectFile" @click:clear="clearData" />
      </v-col>
      <v-col sm="10" md="2">
        <v-select label="Separator" :items="separators" v-model="separator" />
      </v-col>

      <v-col cols="auto" class="ml-2">
        <v-btn color="success" dark small @click="uploadFile">
          Upload
          <v-icon right dark>mdi-cloud-upload</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-alert v-if="message" color="blue-grey" dark>
      {{ message }}
    </v-alert>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "FileUploadCSV",
  props: ["url"],
  data() {
    return {
      currentFile: undefined,
      progress: 0,
      message: "",
      separator: '\t',
      separators: [{text:";", value:";"},{text:",", value:","},{text:"|", value:"|"},{text:"TAB",value:"\t"}],
    };
  },
  computed: {
    ...mapState("authentication", ["token"]),
  },
  methods: {
    selectFile(file) {
      this.progress = 0;
      this.currentFile = file;
    },
    async uploadFile() {
      if (!this.currentFile) {
        this.message = "Nessun file selezionato";
        return;
      }
      this.message = "Attendi...";
      await this.upload((event) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      }).then(
        (response) => {
          this.message = response.data;
        },
        (error) => {
          console.log("AXIOS ERROR", error, "Message:", error.response);
          this.progress = 0;
          this.message = this.getErrorMessage(error);
          if (this.message) this.currentFile = undefined;
        }
      );
    },
    upload(onUploadProgress) {
      let formData = new FormData();
      formData.append("file", this.currentFile);
      formData.append("separator", this.separator);
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${this.token}`,
      };
      return axios.post(this.url, formData, {
        headers: headers,
        onUploadProgress,
      });
    },
    clearData() {
      console.log("CLEAR...");
      this.currentFile = null;
      this.progress = 0;
      this.message = null;
    },
    getErrorMessage(error) {
      let message = "Si è verificato un errore";
      if (error.response && error.response.data) {
        message = error.response.data.message;
      }
      if (message.startsWith("javax.ws.rs.BadRequestException: ")) message = message.substring("javax.ws.rs.BadRequestException: ".length);
      return message;
    },
  },
};
</script>
