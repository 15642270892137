<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="header">Upload CSV</v-card-title>
          <v-card-text>
            <FileUploadCSV ref="fileUpload" :url="uploadUrl" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="header">Go to</v-card-title>
          <v-card-text>
            <v-row align="center" class="mt-2">
              <v-col>
                <a :href="gateUrl + 'E01501'" target="_blank">
                  <v-btn color="primary"> E01501 </v-btn>
                </a>
              </v-col>
              <v-col>
                <a :href="gateUrl + 'U01504'" target="_blank">
                  <v-btn color="primary"> U01504 </v-btn>
                </a>
              </v-col>
              <v-col>
                <a :href="gateUrl + 'admin'" target="_blank">
                  <v-btn color="primary"> Admin </v-btn>
                </a>
              </v-col>
              <v-col>
                <a :href="baseUrl + 'tap/#'" target="_blank">
                  <v-btn color="primary"> Tap </v-btn>
                </a>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="header">Enter Carrier</v-card-title>
          <v-card-text>
            <v-row align="center">
              <v-col cols="4">
                <v-select v-model="carrierVisit" :items="carrierVisitsExpected" />
              </v-col>
              <v-col cols="4">
                <v-btn color="primary" @click="enterCarrierVisit">ENTER</v-btn>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="12">
                {{enterCarrierMsg}}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FileUploadCSV from "@/components/misc/FileUploadCSV";
export default {
  name: "CsvImporter",
  components: { FileUploadCSV },
  data() {
    return {
      uploadUrl: `${this.$apiConfiguration.BASE_PATH_YARD}tos/gate/upload-csv`,
      gateUrl: `${this.$apiConfiguration.BASE_PATH_ROOT}gate/#/`,
      baseUrl: `${this.$apiConfiguration.BASE_PATH_ROOT}`,
      carrierVisitsExpected: null,
      carrierVisit: null,
      enterCarrierMsg: ""
    };
  },
  async mounted() {
    this.carrierVisitsExpected = await this.$api.yardData.getCarrierVisitsExpected();
    console.log("------------------- carrierVisitsExpected", this.carrierVisitsExpected);
  },
  methods: {
    async enterCarrierVisit() {
      this.enterCarrierMsg = "";
      try {
        await this.$api.yardData.enterCarrierVisit(this.carrierVisit);
        this.enterCarrierMsg = "Carrier visit " + this.carrierVisit + " entered.";
      } catch (e) {
        this.enterCarrierMsg = "Error entering carrier visit. " + e;
      }     
      this.carrierVisitsExpected = await this.$api.yardData.getCarrierVisitsExpected();
    }
  }
};
</script>
